<template>
  <v-card
    :loading="fileExplorer.loading"
    :disabled="fileExplorer.loading"
    min-height="800"
  >
    <v-card-title>Обмен файлами с {{ fileExplorer.title }}</v-card-title>
    <v-card-actions>
      <v-btn @click="up">Вверх</v-btn>
      <v-card-text>{{ fileExplorer.folderPath }}</v-card-text>
      <v-btn @click="downloadFile" :disabled="!downloadButtonActive"
        >Скачать</v-btn
      >
      <v-file-input
        label="Выберите файл"
        show-size
        counter
        truncate-length="25"
        @change="fileChanged"
        id="file"
      ></v-file-input>
      <v-btn @click="uploadFile" :disabled="!file">Загрузить файл</v-btn>
    </v-card-actions>
    <v-treeview
      :items="sortedFiles"
      activatable
      item-key="name"
      :active.sync="selected"
    >
      <template v-slot:prepend="{ item }">
        <v-icon
          v-if="['dir', 'drive'].includes(item.type)"
          @click="openDir(item.name)"
        >
          {{ extentions[item.ext] }}
        </v-icon>
        <v-icon v-else>
          {{ extentions[item.ext] }}
        </v-icon>
      </template>
    </v-treeview>
  </v-card>
</template>

<script>
import { $host } from "../libs/user-api";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      selected: [],
      initiallyOpen: ["public"],
      file: null,
      extentions: {
        bak: "mdi-backup-restore",
        csv: "mdi-microsoft-excel",
        log: "mdi-math-log",
        xml: "mdi-xml",
        zip: "mdi-folder-zip",
        drive: "mdi-harddisk",
        dir: "mdi-folder",
        html: "mdi-language-html5",
        js: "mdi-nodejs",
        json: "mdi-code-json",
        md: "mdi-language-markdown",
        pdf: "mdi-file-pdf",
        png: "mdi-file-image",
        txt: "mdi-file-document-outline",
        xls: "mdi-file-excel",
      },
      // bpId: 1029,
    };
  },
  methods: {
    async downloadFile() {
      let response = await $host.get("/api/clientFile", {
        params: {
          folderPath: this.folderPath,
          fileName: this.selected[0],
          bpId: this.bpId,
        },
      });
      this.forceFileDownload(response, this.selected[0]);
    },
    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    fileChanged(file) {
      this.file = file;
    },
    async uploadFile() {
      // let fileType = file.type
      // let sizeOfFile = file.size
      let formData = new FormData();
      formData.append("file", this.file);
      await $host.post("/api/clientFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    ...mapActions(["getFiles", "up", "openDir"]),
  },
  // async created() {
  //   await this.getFiles();
  // },
  // async maunted() {
  //   await this.getFiles();
  // },
  computed: {
    sortedFiles() {
      return _.sortBy(this.fileExplorer.files, (f) =>
        f.type == "dir" ? 1 : 2
      );
    },
    downloadButtonActive() {
      return !!this.selected;
    },
    ...mapGetters(["fileExplorer"]),
  },
};
</script>

<style lang="scss" scoped>
</style>